import React from "react";
import { CHART_COLOR_CONFIG } from "../../center/variable";

const ChartLegendContainer = ({ legendLabels }) => {
    console.log("ChartLegendContainer legendLabels");
    console.log(legendLabels);
    const chartLegend = legendLabels.map((item, index) => {
        return (
            <div
                className="mx-3 d-flex flex-column align-items-center"
                key={index}
            >
                <div
                    className="color my-2"
                    style={{
                        backgroundColor: CHART_COLOR_CONFIG[index].borderColor,
                    }}
                ></div>
                {item}
            </div>
        );
    });
    return (
        <div className="chart-legend-container d-flex justify-content-center">
            {chartLegend}
        </div>
    );
};

export default ChartLegendContainer;
