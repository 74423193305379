import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    APP_VERSION,
    API_URL,
    LOCAL_APPTOKEN_NAME,
    LOCAL_UUID_NAME,
} from "../center/variable";
import {
    getDeviceAccess,
    getDevicePlatform,
    getDeviceOS,
} from "../center/common";
import { v4 as uuidv4 } from "uuid";

// import Overview from "./dashboard/Overview";

// function redirectme() {}

// const API_URL = API_PROD;
const Login = () => {
    const usernameRef = useRef();
    const passwordRef = useRef();
    // console.log(uuidv4());
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    let navigate = useNavigate();

    let deviceId = localStorage.getItem(LOCAL_UUID_NAME);

    if (deviceId && deviceId != "undefined")
        deviceId = localStorage.getItem(LOCAL_UUID_NAME);
    else {
        deviceId = uuidv4();
        localStorage.setItem(LOCAL_UUID_NAME, deviceId);
    }

    console.log(
        "localStorage.get(LOCAL_UUID_NAME) " +
            localStorage.getItem(LOCAL_UUID_NAME)
    );
    console.log("UUID " + deviceId);

    let userData = {
        username: "",
        password: "",
        access: getDeviceAccess(),
        appversion: APP_VERSION,
        osversion: getDeviceOS(),
        device: getDevicePlatform(),
        deviceid: deviceId,
    };
    console.log(userData);

    function submitHandle(e) {
        // console.log(usernameRef.current.value);
        e.preventDefault();
        //vespaadmin
        //vadamin

        let username = usernameRef.current.value;
        let password = passwordRef.current.value;
        console.log(username, password);
        userData = { ...userData, password, username };
        console.log(userData);
        let url = API_URL + "AdminAuthen";
        // let url = API_PROD + "AdminAuthen";
        let config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        };

        console.log(url);
        fetch(url, config)
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem(LOCAL_APPTOKEN_NAME, data.apptoken);

                console.log(data);
                usernameRef.current.value = "";
                passwordRef.current.value = "";
                if (data.result === "error") alert(data.detail);
                else navigate("/dashboard");
            });
    }

    return (
        <div className="row g-0 app-auth-wrapper">
            <div className="col-12  auth-main-col text-center p-5">
                <div className="d-flex flex-column align-content-end">
                    <div className="app-auth-body mx-auto">
                        <div className="app-auth-branding mb-4">
                            <a className="app-logo" href="/">
                                <img
                                    className="logo-icon me-2"
                                    src="assets/images/logo.png"
                                    alt="logo"
                                    style={{ borderRadius: "50%" }}
                                />
                            </a>
                        </div>
                        <h2 className="auth-heading text-center mb-5">
                            BOOKING SERVICE STAT
                        </h2>
                        <div className="auth-form-container text-start">
                            <form
                                className="auth-form login-form"
                                onSubmit={submitHandle}
                            >
                                <div className="email mb-3">
                                    <label className="sr-only">User Name</label>
                                    <input
                                        id="signin-username"
                                        type="text"
                                        className="form-control signin-email"
                                        placeholder="User Name"
                                        required="required"
                                        // value={username}
                                        ref={usernameRef}
                                        autoComplete="username"
                                        // onChange={(e) =>
                                        //     setUsername(e.target.value)
                                        // }
                                    />
                                </div>
                                <div className="password mb-3">
                                    <label
                                        className="sr-only"
                                        htmlFor="signin-password"
                                    >
                                        Password
                                    </label>
                                    <input
                                        id="signin-password"
                                        name="signin-password"
                                        type="password"
                                        className="form-control signin-password"
                                        placeholder="Password"
                                        required="required"
                                        ref={passwordRef}
                                        // value={password}
                                        // onChange={(e) =>
                                        //     setPassword(e.target.value)
                                        // }
                                    />
                                    <div className="extra mt-3 row justify-content-between"></div>
                                </div>
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn app-btn-primary w-100 theme-btn mx-auto"
                                    >
                                        Log In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
