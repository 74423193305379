import SidePanel from "./SidePanel";
import TopPanel from "./TopPanel";

const Header = () => {
    return (
        <header className="app-header fixed-top">
            <TopPanel />
            <SidePanel />
        </header>
    );
};
export default Header;
