import "./App.css";
import Login from "./page/Login";

import Dashboard from "./page/Dashboard";
import { Routes, Route } from "react-router-dom";
import DashboardDataProvider from "./store/DashboardDataProvider";
// import { browserHistory } from "react-router-";

function App() {
    // useEffect(() => {
    //     //FECTH
    //     // window.location.href = "/dashbaord";
    //     // let navigate = useNavigate();
    //     // navigate("/dashbaord");
    //     // fetchData();
    //     // callme();

    //     // function callme() {
    //     //     let navigate = useNavigate();
    //     //     navigate("/dashbaord");
    //     // }
    // }, []);

    // async function fetchData() {
    //     let token = localStorage.getItem(LOCAL_APPTOKEN_NAME);
    //     console.log("token : " + token);
    //     let url = API_URL + "AdminCheckToken";

    //     let config = {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ apptoken: token }),
    //     };
    //     // fetch(url, config)
    //     //     .then((response) => response.json())
    //     //     .then((data) => {
    //     //         if (
    //     //             data.result === "complete" &&
    //     //             data.detail === "token_valid"
    //     //         ) {
    //     //             console.log("DONE");
    //     //             // let navigate = useNavigate();
    //     //             // navigate("/dashbaord");
    //     //             // window.location.href = "/dashboard";
    //     //             // browserHistory.push("/dashboard");
    //     //         }
    //     //     });

    //     let response = await fetch(url, config);
    //     let data = await response.json();
    //     if (data.result === "complete" && data.detail === "token_valid") {
    //         console.log("DONE");
    //         let navigate = useNavigate();
    //         navigate("/dashbaord");
    //         // window.location.href = "/dashboard";
    //         // browserHistory.push("/dashboard");
    //     }
    // }

    return (
        <DashboardDataProvider>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
            {/* <Dashboard /> */}
            {/* <Login /> */}
        </DashboardDataProvider>
    );
}

export default App;
