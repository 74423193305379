import LineChart from "../../components/dashboard/LineChart";
import { API_URL } from "../../center/variable";
// import DoughnutChart from "../../components/dashboard/DoughnutChart";
import { numberWithCommas, fetchDashboardData } from "../../center/common";
import Card from "../../components/dashboard/Card";
import Row from "../../components/Row";
import Col from "../../components/Col";
import { useEffect, useState } from "react";

const defaultDashboardData = {
    lineData: {
        title: "line data",
        data: [],
    },
    cardData: {
        title: "card Data",
        data: [],
    },
};

// const API_URL = API_PROD;
const DashboardMain = ({ dateRange, dateType }) => {
    console.log("? DashboardMainData");
    console.log("dateRange --- " + dateRange);
    console.log("dateType --- " + dateType);
    const [dashboardData, setDashboardData] = useState(defaultDashboardData);

    useEffect(() => {
        let dashboardMainUrl = API_URL + "DashboardMain";
        fetchDashboardData(dashboardMainUrl, dateType, dateRange).then((data) => {
            console.log("fetchDashboardData complete");
            console.log(data);
            if (data.result === "complete") {
                setDashboardData({
                    lineData: {
                        title: "จำนวน Booking",
                        data: data.statsummarydetail,
                    },
                    cardData: {
                        title: "card Data",
                        data: data.statsummary,
                    },
                });
            }
        });

        return () => {
            // setDashboardData(defaultDashboardData);
            // second;
        };
    }, [dateRange, dateType]);

    // let dashboardMainUrl = API_URL + "DashboardMain";
    // fetchDashboardData(dashboardMainUrl, dateType, dateRange).then((data) => {
    //     console.log("fetchDashboardData complete");
    //     console.log(data);
    //     if (data.result == "complete") {
    //         setDashboardData({
    //             lineData: {
    //                 title: "line data",
    //                 data: data.statsummarydetail,
    //             },
    //             cardData: {
    //                 title: "card Data",
    //                 data: data.statsummary,
    //             },
    //         });
    //     }
    // });

    const CardsComponent = dashboardData.cardData.data.map((card) => {
        console.log("card.statlabel " + card.statlabel);
        let titleTh;
        if (card.statlabel.toLowerCase() == "total booking")
            titleTh = "จำนวนที่ทำการ Booking  แล้ว";
        else if (card.statlabel.toLowerCase() == "total done")
            titleTh = "จำนวนรถที่ทำการอัพเกรดเรียบร้อยแล้ว";
        else if (card.statlabel.toLowerCase() == "total customerdata")
            titleTh = "จำนวนรถทั้งหมดที่ต้องอัพเกรด";
        else if (card.statlabel.toLowerCase() == "total rest") titleTh = "จำนวนรถที่เหลือ";

        return (
            <Col col="col-6 col-lg-3" key={card.index}>
                {/* <Card title={card.statlabel} value={card.value} percent={card.valuepercent} /> */}
                <Card
                    title={titleTh}
                    value={numberWithCommas(card.value)}
                    percent={card.valuepercent}
                />
            </Col>
        );
    });

    const LineComponent = ({ config }) => {
        console.log("? Line");
        // console.log(config);
        let datasets = [
            {
                label: "จำนวนรถที่ทำการ Booking แล้ว",
                data: config.data.map((item) => item.booking),
            },
            {
                label: "จำนวนรถที่อัพเกรดแล้ว",
                data: config.data.map((item) => item.done),
            },
        ];

        return (
            <LineChart
                title={config.title}
                data={config.data}
                datasets={datasets}
                labels={config.data.map((item) => item.statdate)}
            />
        );
    };

    // const Doughnut = () => {
    //     return <DoughnutChart title="จำนวนรถที่ upgrade แล้ว" />;
    // };

    return (
        <>
            <Row>{CardsComponent}</Row>

            <Row>
                <Col col="col-12">
                    <LineComponent config={dashboardData.lineData} />
                </Col>
            </Row>

            <Row>
                <Col col="col-6">{/* <Doughnut config={dashboardData.lineData} /> */}</Col>
            </Row>
        </>
    );
};
export default DashboardMain;
