import React from "react";

const TableChart = ({ title, data }) => {
    console.log(data);

    const tableComponent = data.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.statlabel}</td>
                <td>{item.value}</td>
            </tr>
        );
    });
    return (
        <div className="app-card app-card-chart h-100 shadow-sm">
            <div className="app-card-header p-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h4 className="app-card-title">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="app-card-body p-3">
                {/* <div className="mb-3 d-flex"></div> */}
                <div className="chart-container">
                    <table className="table app-table-hover mb-0 text-left">
                        <thead>
                            <tr>
                                <th>Province</th>
                                <th>Data</th>
                                {/* <th>Percent</th> */}
                            </tr>
                        </thead>

                        <tbody>{tableComponent}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TableChart;
