import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import ChartLegendContainer from "./ChartLegendContainer";
Chart.register(...registerables, ChartDataLabels);

// const DoughnutChart = ({ title, data }) => {
const DoughnutChart = ({ title, data }) => {
    console.log("? DoughnutChart");
    // console.log(data);
    const doughnutCanvasRef = useRef();
    useEffect(() => {
        const doughnutChart = new Chart(doughnutCanvasRef.current, config);
        return () => {
            doughnutChart.destroy();
        };
    }, [data]);

    let legendLabels = data.map((item) => item.statlabel);
    console.log("legendLabels");
    console.log(legendLabels);

    let dataForDataset = data.map((item) => item.value);
    console.log(dataForDataset);
    const doughnutData = {
        labels: data.map((item) => item.statlabel),
        datasets: [
            {
                // label: "My First Dataset",
                // data: [300, 50, 100],
                data: dataForDataset,
                backgroundColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                ],
                hoverOffset: 4,
            },
        ],
    };

    const config = {
        type: "doughnut",
        data: doughnutData,
        options: {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: true,
                    color: "white",
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderColor: "white",
                    borderRadius: 25,
                    borderWidth: 2,
                    color: "white",
                },
            },
        },
    };
    return (
        <div className="app-card app-card-chart h-100 shadow-sm">
            <div className="app-card-header p-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h4 className="app-card-title">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="app-card-body p-3 p-lg-4">
                <div className="chart-container">
                    <canvas
                        ref={doughnutCanvasRef}
                        className="chart-container"
                    ></canvas>
                </div>
                <ChartLegendContainer legendLabels={legendLabels} />
            </div>
        </div>
    );
};

export default DoughnutChart;
