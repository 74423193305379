import { LOCAL_APPTOKEN_NAME } from "./variable";
// import platform from "platform";

// console.log(platform);
// console.log(platform.os.family);
export let changeDateToAPIFormat = (timestamp) => {
    let day = new Date(timestamp);
    let month = ("0" + (day.getMonth() + 1)).slice(-2);
    let date = ("0" + day.getDate()).slice(-2);
    return `${day.getFullYear()}-${month}-${date}`;
};

export let fetchDashboardData = async (url, dateType, dateRange) => {
    let APP_TOKEN = localStorage.getItem(LOCAL_APPTOKEN_NAME);
    if (!APP_TOKEN) return "no app token";
    let requestData = {
        apptoken: APP_TOKEN,
        statdatebegin: changeDateToAPIFormat(dateRange[0]),
        statdateend: changeDateToAPIFormat(dateRange[1]),
        statperiod: dateType,
    };
    let config = {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
    };

    console.log("requestData");
    console.log(requestData);

    // return fetch(url, config)
    //     .then((response) => response.json())
    //     .then((result) => result);
    let response = await fetch(url, config);
    return await response.json();
};

export const getDeviceAccess = () => {
    const ua = navigator.userAgent;
    if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    } else return "desktop";
};

export const getDevicePlatform = () => {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "mac";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "ios";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "window";
    } else if (/Android/.test(userAgent)) {
        os = "android";
    } else {
        os = "unknown";
    }
    console.log("getOSDevice " + os);
    return os;
};

export const getDeviceOS = () => {
    var userAgent = navigator.userAgent.toLowerCase();
    let windowos = {
        isWin2K: /windows nt 5.0/.test(userAgent),
        isXP: /windows nt 5.1/.test(userAgent),
        isVista: /windows nt 6.0/.test(userAgent),
        isWin7: /windows nt 6.1/.test(userAgent),
    };

    let OS;
    if (windowos.isWin2K) OS = "Windows 2000";
    if (windowos.isXP) OS = "Windows XP";
    if (windowos.isVista) OS = "Windows Vista";
    if (windowos.isWin7) OS = "Windows 7";
    else OS = "Window";
    return OS;
};

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
