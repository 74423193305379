import { API_URL } from "../../center/variable";
// import DoughnutChart from "../../components/dashboard/DoughnutChart";
import ModelChart from "../../components/dashboard/ModelChart";
import { fetchDashboardData } from "../../center/common";
import Row from "../../components/Row";
import Col from "../../components/Col";
import DoughnutChart from "../../components/dashboard/DoughnutChart";
import TableChart from "../../components/dashboard/TableChart";
import { useEffect, useState } from "react";

const defaultDashboardData = {
    doughnut: {
        title: "doughnut data",
        data: [],
    },
    table: {
        title: "qweqwe",
        data: [],
    },
    model: {
        title: "qwe",
        data: [],
    },
};

// const API_URL = API_PROD;
const DashboardDetail = ({ dateRange, dateType }) => {
    console.log("? DashboardDetail");
    console.log("dateRange --- " + dateRange);
    console.log("dateType --- " + dateType);

    const [dashboardData, setDashboardData] = useState(defaultDashboardData);
    console.log("dashboardData --- ");
    console.log(dashboardData);
    useEffect(() => {
        let url = API_URL + "DashboardDetail";
        fetchDashboardData(url, dateType, dateRange).then((data) => {
            console.log("fetchDashboardData complete");
            console.log(data);
            if (data.result == "complete") {
                setDashboardData({
                    doughnut: {
                        title: "จำนวนรถที่อัพเกรดแล้ว",
                        data: data.statservicetype,
                    },
                    table: {
                        title: "Top 10 Dealer ที่มีรถเข้าอัพเกรดแล้ว",
                        data: data.statservicefacade,
                    },
                    model: {
                        title: "Model รถที่มีการเข้าอัพเกรดแล้ว",
                        data: data.statmodel,
                    },
                });
            }
        });

        return () => {
            // second;
        };
    }, [dateRange, dateType]);

    return (
        <>
            <Row>
                <Col col="col-12 col-md-6">
                    <DoughnutChart
                        title={dashboardData.doughnut.title}
                        data={dashboardData.doughnut.data}
                    />
                </Col>
                <Col col="col-12 col-md-6">
                    <TableChart
                        title={dashboardData.table.title}
                        data={dashboardData.table.data}
                    />
                </Col>
            </Row>
            <Row>
                <Col col="col-12 col-md-6">
                    <ModelChart
                        title={dashboardData.model.title}
                        data={dashboardData.model.data}
                    />
                </Col>
            </Row>
        </>
    );
};
export default DashboardDetail;
