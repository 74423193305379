import Header from "../components/menu/Header";
import DashboardMain from "./dashboard/dashboardMain";
import DashboardDetail from "./dashboard/dashboardDetail";
import { useEffect, useState } from "react";
import DatePicker from "../components/ui/DateSelector";
import Col from "../components/Col";
import Row from "../components/Row";
import ButtonGroup from "../components/ui/ButtonGroup";

import "./Dashboard.css";

// const API_URL = API_PROD;

const dateRangeDefault = {
    startDate: new Date().setDate(new Date().getDate() - 6),
    endDate: new Date().getTime(),
};

const Dashboard = () => {
    // let dateType = "dairy";
    // console.log("? Dashboard 1 " + dateRange);
    const [dateRange, setDateRange] = useState([
        dateRangeDefault.startDate,
        dateRangeDefault.endDate,
    ]);
    // const [dashboardData, setDashboardData] = useState(defaultDashboardData);
    // const [dashboardDetail, setDashboardDetail] = useState();
    const [dateType, setDateType] = useState("daily"); //""daily""/""weekly""/""monthly"""

    // console.log("? Dashboard 2 " + dateRange);
    // const [startDate, endDate] = dateRange;onDatePickupChange

    // useEffect(() => {
    //     let dashboardMainUrl = API_URL + "DashboardMain";
    //     let dashboardDetailUrl = API_URL + "DashboardDetail";

    //     // fetchDashboardData(dashboardMainUrl, dateType, dateRange).then(
    //     //     (data) => {
    //     //         console.log("fetchDashboardData complete");
    //     //         console.log(data);
    //     //         if (data.result == "complete") {
    //     //             setDashboardData({
    //     //                 lineData: {
    //     //                     title: "line data",
    //     //                     data: data.statsummarydetail,
    //     //                 },
    //     //                 cardData: {
    //     //                     title: "card Data",
    //     //                     data: data.statsummary,
    //     //                 },
    //     //             });
    //     //         }
    //     //     }
    //     // );

    //     fetchDashboardData(dashboardDetailUrl, dateType, dateRange).then(
    //         (data) => {
    //             console.log("fetchDashboardData");
    //             console.log(data);
    //             if (data.result == "complete") {
    //                 // setDashboardData({
    //                 //     lineData: {
    //                 //         title: "line data",
    //                 //         data: data.statsummarydetail,
    //                 //     },
    //                 //     cardData: {
    //                 //         title: "card Data",
    //                 //         data: data.statsummary,
    //                 //     },
    //                 // });
    //             }
    //         }
    //     );

    //     // console.log(mainResult);
    //     // if (mainResult.result == "complete") {
    //     //     setDashboardData({
    //     //         lineData: {
    //     //             title: "line data",
    //     //             data: mainResult.statsummarydetail,
    //     //         },
    //     //         cardData: {
    //     //             title: "card Data",
    //     //             data: mainResult.statsummary,
    //     //         },
    //     //     });
    //     // }
    // }, [dateType, dateRange]);

    // useEffect(() => {});

    function onDateTypeChangeHandle(datetype) {
        console.log("onDateTypeChangeHandle " + datetype);
        // dateType = datatype;
        // startDate
        setDateType(datetype);
    }

    function onGetDateRange(dataRange) {
        console.log("onGetDateRange");
        console.log(dataRange);
        setDateRange(dataRange);
    }

    return (
        <div className="app">
            <Header />
            <div className="app-wrapper">
                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl">
                        <Row>
                            <Col col="col-12 col-md-6">
                                Service Name
                                {}
                                <h1 className="app-page-title">
                                    Booking Service Stats
                                </h1>
                            </Col>
                            <Col col="col-12 col-md-6">
                                <Row>
                                    <Col col="col-6">
                                        Select Date Range
                                        <DatePicker
                                            dateRangeList={dateRange}
                                            onSendDateRange={onGetDateRange}
                                        />
                                    </Col>
                                    <Col col="col-6">
                                        Select Date Type
                                        <ButtonGroup
                                            dateTypeChange={
                                                onDateTypeChangeHandle
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <DashboardMain
                            dateType={dateType}
                            dateRange={dateRange}
                        />
                        <DashboardDetail
                            dateType={dateType}
                            dateRange={dateRange}
                        />
                        {/* {dashboardData && (
                            
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
