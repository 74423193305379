import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import ChartLegendContainer from "./ChartLegendContainer";
import { CHART_COLOR_CONFIG } from "../../center/variable";
import "./LineChart.css";
// import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from `dist/chart.js`
// Chart.register(LineController, LineElement, PointElement, LinearScale, Title);

// import { Chart, registerables } from "chart.js";
console.log("registerables");
// console.log(registerables);
// let regis = [...registerables];
// let { Title, SubTitle, Legend, Tooltip, ...newObj } = regis[2];
// regis[2] = newObj;
// regis[2] = "";
// regis[0] = "";
// let regis = registerables.slice(0);
// regis.pop();

// delete regis[2]["Title"];
// regis[2].Title ;
// console.log(Title);
// console.log(qwe);

// regis[2] = qwe;
// console.log(regis);
// let qwe = regis[2];
// delete qwe.Title;
// console.log(qwe.Title);
// console.log(registerables[2].Title);
// Chart.register(...regis);

const LineChart = ({ title, labels, datasets }) => {
    console.log("? LineChart");

    const lineCanvasRef = useRef();

    useEffect(() => {
        const lineChart = new Chart(lineCanvasRef.current, config);
        return () => {
            lineChart.destroy();
        };
    }, []);

    let legendLabels = datasets.map((item) => item.label);
    console.log("legendLabels");
    console.log(legendLabels);

    let lineDataset = datasets.map((item, index) => {
        return { ...item, ...CHART_COLOR_CONFIG[index] };
    });

    const config = {
        type: "line",
        data: {
            labels,
            datasets: lineDataset,
        },
        options: {
            pointRadius: 5,
            pointBackgroundColor: "rgba(0, 0, 0, 1)",
            // pointBorderColor: "rgba(0, 0, 0, 1)",
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false,
                },
            },
        },
    };

    return (
        // <div className="row g-4 mb-4">
        // <div className="col-12">
        <div className="app-card app-card-chart h-100 shadow-sm">
            <div className="app-card-header p-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h4 className="app-card-title">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="app-card-body p-3 p-lg-4">
                {/* <div className="mb-3 d-flex"></div> */}
                <div className="chart-container">
                    {/* <Line options={options} data={config} /> */}
                    <canvas ref={lineCanvasRef} id="canvas-linechart"></canvas>
                </div>

                <ChartLegendContainer legendLabels={legendLabels} />
            </div>
            {/* <div className="app-card-footer">qwe</div> */}
        </div>
        // </div>
        // </div>
    );
};
export default LineChart;
