import React from "react";

const Card = ({ title = "vespa", percent = 0, value = 0 }) => {
    return (
        <div className="app-card app-card-stat shadow-sm h-100">
            <div className="app-card-body p-3 p-lg-4">
                <h4 className="stats-type mb-1" style={{ minHeight: "2rem" }}>
                    {title}
                </h4>
                <div className="row align-items-center">
                    <h3 className="col-12 fw-bold">{percent}%</h3>
                    <h4 className="col-12 mb-0 col-auto text-success">{value}</h4>
                </div>
            </div>
            {/* <a className="app-card-link-mask" href="#"></a> */}
        </div>
    );
};

export default Card;
