const SidePanel = () => {
    return (
        <div id="app-sidepanel" className="app-sidepanel sidepanel-visible">
            <div id="sidepanel-drop" className="sidepanel-drop"></div>
            <div className="sidepanel-inner d-flex flex-column">
                <a
                    href="#"
                    id="sidepanel-close"
                    className="sidepanel-close d-xl-none"
                >
                    ×
                </a>
                <div className="app-branding">
                    <a className="app-logo" href="/">
                        <img
                            className="logo-icon me-2"
                            src="assets/images/logo.png"
                            alt="logo"
                            style={{ borderRadius: "50%" }}
                        />
                        <span className="logo-text">VESPA</span>
                    </a>
                </div>
                <nav
                    id="app-nav-main"
                    className="app-nav app-nav-main flex-grow-1"
                >
                    <ul
                        className="app-menu list-unstyled accordion"
                        id="menu-accordion"
                    >
                        <li className="nav-item">
                            <a className="nav-link active" href="/">
                                <span className="nav-icon">
                                    <svg
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 16 16"
                                        className="bi bi-house-door"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z"
                                        ></path>
                                        <path
                                            fillRule="evenodd"
                                            d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="nav-link-text">Overview</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SidePanel;
