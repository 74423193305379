import React, { useReducer } from "react";
import DashboardDataContext from "./dashboarddata-context";
import { LOCAL_APPTOKEN_NAME } from "../center/variable";

const defaultDashboardData = {
    apptoken: localStorage.getItem(LOCAL_APPTOKEN_NAME),
    statdatebegin: "",
    statdateend: "",
    statperiod: "",
};

const dashboardDataReducer = (state, action) => {
    return defaultDashboardData;
};
const DashboardDataProvider = (props) => {
    const [dashboardDataState, dispatchDashboardDataAction] = useReducer(
        dashboardDataReducer,
        defaultDashboardData
    );
    const updateDashboardDataHandler = (sth) => {};

    const dashboardDataContext = {
        data: {
            appToken: dashboardDataState.apptoken,
            statdatebegin: new Date().setDate(new Date().getDate() - 6),
            statdateend: new Date().getTime(),
            statperiod: "dairy",
        },

        updateDashboardData: updateDashboardDataHandler,
    };
    return (
        <DashboardDataContext.Provider value={dashboardDataContext}>
            {props.children}
        </DashboardDataContext.Provider>
    );
};

export default DashboardDataProvider;
