export const APP_VERSION = "1.0";
export const LOCAL_APPTOKEN_NAME = "vsapptoken";
export const LOCAL_UUID_NAME = "vsuuid";

var randomDataPoint = function () {
    return Math.round(Math.random() * 10000);
};

const API_PROD = "//bookingapi.vespiario-system.com/BookingServices/api/";
const API_STAGING = "//bookingapidev.vespiario-system.com/BookingServices/api/";

export const CHART_COLOR_CONFIG = [
    {
        pointBackgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        tension: 0.3,
    },
    {
        pointBackgroundColor: "rgb(53, 162, 235)",
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        tension: 0.3,
    },
];

export const API_URL = API_PROD;
