import React, { useState } from "react";
import "./ButtonGroup.css";
const ButtonGroup = (props) => {
    console.log("? ButtonGroup");
    // const [selectDateType, setSelectDateType] = useState("daily");
    function onSelectDateType({ target }) {
        console.log("onSelectDate " + target.value);
        props.dateTypeChange(target.value);
    }

    //""daily""/""weekly""/""monthly"""
    return (
        <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
            onChange={onSelectDateType}
        >
            <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="day"
                autoComplete="off"
                value="daily"
                defaultChecked
            />
            <label className="btn btn-outline-primary" htmlFor="day">
                Day
            </label>

            <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="week"
                autoComplete="off"
                value="weekly"
            />
            <label className="btn btn-outline-primary" htmlFor="week">
                Week
            </label>

            <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="month"
                autoComplete="off"
                value="monthly"
            />
            <label className="btn btn-outline-primary" htmlFor="month">
                Month
            </label>
        </div>
    );
};

export default ButtonGroup;
