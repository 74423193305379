import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateSelector = ({ dateRangeList, onSendDateRange }) => {
    const [dateRange, setDateRange] = useState(dateRangeList);

    function onDatePickupChange(update) {
        console.log("onDatePickupChange " + update);
        // setDateRange((prev) => {
        //     console.log(prev);
        //     console.log(update);
        //     console.log("setDateRange");
        //     return update;
        // });
        setDateRange(update);
    }

    function onCloseCalendar() {
        console.log("onCloseCalendar");
        console.log(dateRange.length);
        if (dateRange[1] !== null) onSendDateRange(dateRange);
    }
    return (
        <DatePicker
            selectsRange={true}
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            minDate={new Date(2022, 2, 7)}
            maxDate={new Date()}
            onChange={(update) => {
                onDatePickupChange(update);
            }}
            onCalendarClose={onCloseCalendar}
            className="form-control search-docs"
            // isClearable={true}
        />
    );
};

export default DateSelector;
