const TopPanel = () => {
    return (
        <div className="app-header-inner">
            <div className="container-fluid py-2">
                <div className="app-header-content">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <a
                                id="sidepanel-toggler"
                                className="sidepanel-toggler d-inline-block d-xl-none"
                                href="/"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    role="img"
                                >
                                    <title>Menu</title>
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="2"
                                        d="M4 7h22M4 15h22M4 23h22"
                                    ></path>
                                </svg>
                            </a>
                        </div>
                        <div className="search-mobile-trigger d-none col">
                            <svg
                                className="svg-inline--fa fa-search fa-w-16 search-mobile-trigger-icon"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="search"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                            >
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </svg>
                        </div>

                        <div className="app-utilities col-auto">
                            <div className="app-utility-item app-user-dropdown dropdown">
                                <a
                                    className="dropdown-toggle"
                                    id="user-dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    href="#username"
                                    role="button"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="https://ui-avatars.com/api/?background=random&name=vespaadmin"
                                        alt="user profile"
                                        style={{ borderRadius: "8px" }}
                                    />
                                </a>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="user-dropdown-toggle"
                                >
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="account.html"
                                        >
                                            Account
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="settings.html"
                                        >
                                            Settings
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="login.html"
                                        >
                                            Log Out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopPanel;
