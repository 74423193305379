import React from "react";
import style from "./ModelChart.module.css";

const ModelChart = ({ title, data }) => {
    console.log(data);
    data.sort((a, b) => {
        return b.value - a.value;
    });
    // console.log(data);
    const modelCompoenet = data.map((item, index) => {
        return (
            <div className={`item p-3 ${style.item}`} key={index}>
                <div className="row align-items-center">
                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col">{item.statlabel}</div>
                            <div className="col text-end">
                                {item.percent}% | {item.value} คน
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="progress">
                            <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: item.percent + "%" }}
                                aria-valuenow={item.percent}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {item.percent}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    //     <div key={index}>
    //     {item.statlabel}, {item.value}
    // </div>

    return (
        <div className="app-card app-card-chart h-100 shadow-sm">
            <div className="app-card-header p-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h4 className="app-card-title">{title}</h4>
                    </div>
                </div>
            </div>
            <div className="app-card-body p-3 p-lg-4">
                {modelCompoenet}

                {/* <div className="chart-container">{modelCompoenet}</div> */}
            </div>
        </div>
    );
};

export default ModelChart;
